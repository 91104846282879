.grid-container {
    display: grid;
    width: 100%;
    height: 500px; /* Adjust the height as needed */
    background-size: cover;
    background-position: center;
  }
  
  .text-overlay {
    grid-area: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(0, 0, 0, 0.5); */
  }

  .text-overlay2 {
    grid-area: 1 / 1;
    display: flex;
    justify-content: left;
    align-items: left;
    /* background-color: rgba(0, 0, 0, 0.5); */
  }
  
  .text-overlay h2 {
    color: white;
    text-align: center;
    font-size: 2em;
    padding: 20px;
    margin: 0;
  }

  .black-translucent-card2 {
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% transparency */
    padding: 20px;
    text-align: left;
    color: white;
  }

  .black-translucent-card3 {
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% transparency */
    /* padding: 20px; */
    text-align: left;
    color: white;
  }