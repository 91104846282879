/* body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: monospace;
} */

.background-video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; 
}
.background-landing{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  object-fit: cover;
  z-index: -1; 
}
.video-container{
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.myNave{
  font-weight: 400;
}
.myBtn{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.myaction{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

.mycontainer {
  overflow: hidden; /* Clear floats inside the container */
  margin-bottom: 900px;
  position: absolute;
  top: 280px; /* Set the top offset to 50px */
  right: 0; 
  width: 400px;

  @media screen and (max-width: 960px) {
    display: none;
  }
}

.first-div{
  float: right;
  width: 90%; /* You can adjust this as needed */

 
}

.second-div{
  float: left;
margin-top: 25px;
 
}

.hover-container {
  position: relative;
  height: 100px;
  display: flex;
  cursor: pointer;
  height: 400px;
}

.hover-container .hover-text {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  transition: opacity 0.3s ease;
}

.hover-container:hover .hover-text {
  display: flex;
}

.hover-container:hover .initial-text {
  opacity: 0;
}


.mylink{
  color: #fff;
}
.black-translucent-card {
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% transparency */
  padding: 20px;
  text-align: center;
  color: white;
  width: auto;
}