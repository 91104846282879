.image-map-container {
    position: relative;
  }
  
  .container {
    position: relative;
}

.tooltip {
    position: absolute;
    color: #fff;
    padding: 10px;
    background: rgba(0,0,0,0.8);
    transform: translate3d(-50%, -50%, 0);
    border-radius: 5px;
    pointer-events: none;
    z-index: 1000;
}
